import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { formatDate, formatTime } from "../../../constants/helper";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import {
  Box,
  TextField,
  Typography,
  Divider,
  Pagination,
  Button,
  ButtonGroup,
  Chip,
} from "@mui/material";
import { ShowTableAngsuran } from "../../../components/ShowTable";
import { Loader, usePagination } from "../../../components";
import { Colors } from "../../../constants/styles";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DownloadIcon from "@mui/icons-material/Download";
import EditIcon from "@mui/icons-material/Edit";
import PrintIcon from "@mui/icons-material/Print";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { Col, Row, Form } from "react-bootstrap";

const TampilAngsuran = () => {
  const { user, setting, dispatch } = useContext(AuthContext);
  const location = useLocation();
  const id = location.pathname.split("/")[3];

  const { screenSize } = useStateContext();
  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  // Show Data Jual
  const [jualId, setJualId] = useState("");
  const [sisaBulan, setSisaBulan] = useState("");
  const [namaRegister, setNamaRegister] = useState("");
  const [tlpRegister, setTlpRegister] = useState("");
  const [noJual, setNoJual] = useState("");
  const [tanggalJual, setTanggalJual] = useState("");
  const [tanggalJualText, setTanggalJualText] = useState("");
  const [nopol, setNopol] = useState("");
  const [almRegister, setAlmRegister] = useState("");
  const [kodeSurveyor, setKodeSurveyor] = useState("");
  const [kodeSurveyorAsli, setKodeSurveyorAsli] = useState("");
  const [kodeMarketing, setKodeMarketing] = useState("");
  const [tipe, setTipe] = useState("");
  const [namaTipe, setNamaTipe] = useState("");
  const [hargaTunai, setHargaTunai] = useState("");
  const [uangMuka, setUangMuka] = useState("");
  const [angPerBulan, setAngPerBulan] = useState("");
  const [tenor, setTenor] = useState("");
  const [jumlahPiutang, setJumlahPiutang] = useState("");
  const [tahun, setTahun] = useState("");
  const [namaWarna, setNamaWarna] = useState("");
  const [noRangka, setNoRangka] = useState("");
  const [noMesin, setNoMesin] = useState("");
  const [noRegister, setNoRegister] = useState("");
  const [tglAngText, setTglAngText] = useState("");
  const [tglAngFormatted, setTglAngFormatted] = useState("");
  const [tglAngAkhirText, setTglAngAkhirText] = useState("");
  const [tglAngAkhirFormatted, setTglAngAkhirFormatted] = useState("");
  const [modal, setModal] = useState("");
  const [bunga, setBunga] = useState("");

  const [tglSpTerakhir, setTglSpTerakhir] = useState("");
  const [tglMdTerakhir, setTglMdTerakhir] = useState("");
  const [tglStTerakhir, setTglStTerakhir] = useState("");
  const [tglPenarikan, setTglPenarikan] = useState("");
  const [spKe, setSpKe] = useState("");
  const [customerId, setCustomerId] = useState("");
  const [angsurans, setAngsurans] = useState([]);
  const [angsuranTableRekap, setAngsuranTableRekap] = useState([]);
  const [angsuranTableRinci, setAngsuranTableRinci] = useState([]);

  const [perTgl, setPerTgl] = useState(new Date());
  const [toggleHistory, setToggleHistory] = useState(false);

  const navigate = useNavigate();

  const columnsHistory = [
    { title: "No.", field: "_id" },
    { title: "J. Tempo", field: "tglJatuhTempoFormatted" },
    { title: "Tgl.", field: "tglBayarFormatted" },
    { title: "Bukti", field: "noKwitansi" },
    { title: "Angsuran", field: "angPerBulan" },
    { title: "Denda", field: "denda" },
    { title: "Telat", field: "keterlambatan" },
    { title: "Potongan", field: "potongan" },
    { title: "Bayar", field: "bayar" },
    { title: "Saldo", field: "saldo" },
  ];

  const columnsRekap = [
    { title: "Tgl. Bayar", field: "tglBayarFormatted" },
    { title: "TJ. Tempo", field: "tglJatuhTempoFormatted" },
    { title: "No. Kwitansi", field: "noKwitansi" },
    { title: "Ke", field: "_id" },
    { title: "Keterangan", field: "keterangan" },
    { title: "Bayar", field: "angPerBulan" },
    { title: "Saldo", field: "saldo" },
  ];

  const columnsRinci = [
    { title: "Tgl. Bayar", field: "tglBayarFormatted" },
    { title: "TJ. Tempo", field: "tglJatuhTempoFormatted" },
    { title: "No. Kwitansi", field: "noKwitansi" },
    { title: "Ke", field: "_id" },
    { title: "A. Modal", field: "angModal" },
    { title: "Saldo", field: "modal" },
    { title: "A. Bunga", field: "angBunga" },
    { title: "Saldo", field: "bunga" },
    { title: "Bayar", field: "angPerBulan" },
    { title: "Saldo", field: "saldo" },
  ];

  const [loading, setLoading] = useState(false);
  let [page, setPage] = useState(1);
  const PER_PAGE = 20;

  // Get current posts
  const indexOfLastPost = page * PER_PAGE;
  const indexOfFirstPost = indexOfLastPost - PER_PAGE;
  const currentPosts = angsurans.slice(indexOfFirstPost, indexOfLastPost);

  const count = Math.ceil(angsurans.length / PER_PAGE);
  const _DATA = usePagination(angsurans, PER_PAGE);

  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  let findMonth = (monthNumber) => {
    if (monthNumber === 1) {
      return "JANUARI";
    } else if (monthNumber === 2) {
      return "FEBRUARI";
    } else if (monthNumber === 3) {
      return "MARET";
    } else if (monthNumber === 4) {
      return "APRIL";
    } else if (monthNumber === 5) {
      return "MEI";
    } else if (monthNumber === 6) {
      return "JUNI";
    } else if (monthNumber === 7) {
      return "JULI";
    } else if (monthNumber === 8) {
      return "AGUSTUS";
    } else if (monthNumber === 9) {
      return "SEPTEMBER";
    } else if (monthNumber === 10) {
      return "OKTOBER";
    } else if (monthNumber === 11) {
      return "NOVEMBER";
    } else if (monthNumber === 12) {
      return "DESEMBER";
    }
  };

  let dateText = (date) => {
    let tempDate = new Date(date);
    return `${tempDate.getDate().toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    })} ${findMonth(tempDate.getMonth() + 1)} ${tempDate.getFullYear()}`;
  };

  useEffect(() => {
    id && getJualById();
  }, [id]);

  const getAngsuran = async () => {
    setLoading(true);
    const allAngsuransByNoJual = await axios.post(
      `${tempUrl}/angsuransByNoJual`,
      {
        noJual: id,
        id: user._id,
        token: user.token,
        kodeCabang: user.cabang._id,
      }
    );
    setAngsurans(allAngsuransByNoJual.data.angsuran);
    setModal(
      allAngsuransByNoJual.data.angsuran[0].angModal *
        allAngsuransByNoJual.data.tenor
    );
    setBunga(
      allAngsuransByNoJual.data.angsuran[0].angBunga *
        allAngsuransByNoJual.data.tenor
    );
    setLoading(false);
  };

  const getAngsuranRekap = async () => {
    setLoading(true);
    const allAngsuransByNoJualRekap = await axios.post(
      `${tempUrl}/angsuransByNoJualRekap`,
      {
        noJual: id,
        id: user._id,
        token: user.token,
        kodeCabang: user.cabang._id,
      }
    );
    setAngsuranTableRekap(allAngsuransByNoJualRekap.data);
    setLoading(false);
  };

  const getAngsuranRinci = async () => {
    setLoading(true);
    const allAngsuransByNoJualRinci = await axios.post(
      `${tempUrl}/angsuransByNoJualRinci`,
      {
        noJual: id,
        id: user._id,
        token: user.token,
        kodeCabang: user.cabang._id,
      }
    );
    setAngsuranTableRinci(allAngsuransByNoJualRinci.data);
    setLoading(false);
  };

  const getJualById = async () => {
    if (id) {
      const response = await axios.post(`${tempUrl}/jualByNoJual`, {
        noJual: id,
        id: user._id,
        token: user.token,
        kodeCabang: user.cabang._id,
      });
      if (response.data.tglAng.length !== 0) {
        getAngsuran();
        getAngsuranRekap();
        getAngsuranRinci();
      }
      setJualId(response.data._id);
      setSisaBulan(response.data.sisaBulan);
      setNamaRegister(response.data.namaRegister);
      setTlpRegister(response.data.tlpRegister);
      setNoJual(response.data.noJual);
      setTanggalJual(formatDate(response.data.tanggalJual));
      setTanggalJualText(dateText(response.data.tanggalJual));
      setNopol(response.data.nopol);
      setAlmRegister(response.data.almRegister);
      setKodeSurveyor(response.data.kodeSurveyor);
      setKodeSurveyorAsli(response.data.kodeSurveyorAsli);
      setKodeMarketing(response.data.kodeMarketing);
      setTipe(response.data.tipe);
      const tempTipe = await axios.post(`${tempUrl}/tipesByKode`, {
        kodeTipe: response.data.tipe,
        id: user._id,
        token: user.token,
        kodeCabang: user.cabang._id,
      });
      console.log(tempTipe.data);
      setNamaTipe(tempTipe.data.namaTipe);
      setHargaTunai(response.data.hargaTunai);
      setUangMuka(response.data.uangMuka);
      setAngPerBulan(response.data.angPerBulan);
      setTenor(response.data.tenor);
      setJumlahPiutang(response.data.jumlahPiutang);
      setTahun(response.data.tahun);
      setNamaWarna(response.data.namaWarna);
      setNoRangka(response.data.noRangka);
      setNoMesin(response.data.noMesin);
      setNoRegister(response.data.noRegister);
      setTglAngText(dateText(response.data.tglAng));
      setTglAngFormatted(formatDate(response.data.tglAng));
      setTglAngAkhirFormatted(formatDate(response.data.tglAngAkhir));
      setTglAngAkhirText(dateText(response.data.tglAngAkhir));
      setTglSpTerakhir(formatDate(response.data.tglSpTerakhir));
      setTglMdTerakhir(formatDate(response.data.tglMdTerakhir));
      setTglStTerakhir(formatDate(response.data.tglStTerakhir));
      setTglPenarikan(formatDate(response.data.tglPenarikan));
      setSpKe(response.data.spKe);

      // Find Customer
      const findCustomerByNoKtp = await axios.post(
        `${tempUrl}/customerByNoKtp`,
        {
          noKtpRegister: response.data.noKtpRegister,
          id: user._id,
          token: user.token,
          kodeCabang: user.cabang._id,
        }
      );
      if (findCustomerByNoKtp.data) {
        setCustomerId(findCustomerByNoKtp.data._id);
      }
    }
  };

  const downloadPdfRekap = () => {
    var date = new Date();
    var current_date = formatDate(date);
    var current_time = formatTime(date);
    const doc = new jsPDF();
    let tempY = 28;
    let tempX1 = 45;
    let tempX2 = 164;
    doc.setFontSize(10);
    doc.text(
      `${namaRegister.substring(0, 20)} ( ${tlpRegister} )`,
      tempX1,
      tempY - 1
    );
    doc.text(`${tipe}`, tempX2, tempY);
    tempY += 5;
    doc.text(`${almRegister.substring(0, 30)}`, tempX1, tempY);
    doc.text(`SP. MOTOR`, tempX2, tempY);
    tempY += 6;
    doc.text(`${tanggalJualText}`, tempX1, tempY);
    doc.text(`${noRangka}`, tempX2, tempY);
    tempY += 6;
    doc.text(`${hargaTunai.toLocaleString("en-US")}`, tempX1 + 15, tempY);
    doc.text(`${noMesin}`, tempX2, tempY);
    tempY += 5;
    doc.text(`${uangMuka.toLocaleString("en-US")}`, tempX1 + 15, tempY);
    doc.text(`${nopol}`, tempX2, tempY);
    tempY += 5;
    doc.text(
      `${angPerBulan.toLocaleString("en-US")} x ${tenor}`,
      tempX1 + 15,
      tempY
    );
    doc.text(`${noJual}`, tempX2, tempY);
    tempY += 8;
    doc.text(
      `${jumlahPiutang.toLocaleString("en-US")} / ${tenor}`,
      tempX1 + 15,
      tempY
    );
    doc.text(`${tglAngText}`, tempX2, tempY);
    tempY += 6;
    doc.text(`${tahun} / ${namaWarna}`, tempX1, tempY);
    doc.text(`${tglAngAkhirText}`, tempX2, tempY);
    doc.text(
      `Dicetak Oleh: ${user.username} | Tanggal : ${current_date} | Jam : ${current_time}`,
      15,
      290
    );

    let tempTableX1 = 6;
    let tempTableX2 = 26;
    let tempTableX3 = 50;
    let tempTableX4 = 80;
    let tempTableX5 = 105;
    let tempTableX6 = 135;
    let tempTableX7 = 70;
    let tempTableX8 = 80;

    tempY += 13;
    for (let angsuran of angsuranTableRekap) {
      doc.text(`${angsuran.tglBayarFormatted}`, tempTableX1, tempY);
      doc.text(`${angsuran.tglJatuhTempoFormatted}`, tempTableX2, tempY);
      doc.text(`${angsuran.noKwitansi}`, tempTableX3, tempY);
      doc.text(`${angsuran._id}`, tempTableX4, tempY, {
        align: "right",
      });
      doc.text(`${angsuran.angPerBulan}`, tempTableX5, tempY, {
        align: "right",
      });
      doc.text(`${angsuran.saldo}`, tempTableX6, tempY, {
        align: "right",
      });
      tempY += 5;
    }
    window.open(URL.createObjectURL(doc.output("blob")));
  };

  const downloadPdfRinci = () => {
    var date = new Date();
    var current_date = formatDate(date);
    var current_time = formatTime(date);
    const doc = new jsPDF("l", "mm", [280, 210]);
    doc.setFontSize(12);
    doc.text(`${setting.namaPerusahaan} - ${setting.kotaPerusahaan}`, 15, 10);
    doc.text(`${setting.lokasiPerusahaan}`, 15, 15);
    doc.setFontSize(16);
    doc.text(`Kartu Kredit Rinci`, 120, 30);
    doc.setFontSize(10);
    doc.text(`Nama`, 15, 40);
    doc.text(`: ${namaRegister}`, 43, 40);
    doc.text(`Tipe`, 120, 40);
    doc.text(`: ${tipe}`, 147, 40);
    doc.text(`Alamat}`, 15, 45);
    doc.text(`: ${almRegister.substring(0, 30)}`, 43, 45);
    doc.text(`No. Rangka`, 120, 45);
    doc.text(`: ${noRangka}`, 147, 45);
    doc.text(`Tgl. kontrak`, 15, 50);
    doc.text(`: ${tanggalJualText}`, 43, 50);
    doc.text(`No. Mesin`, 120, 50);
    doc.text(`: ${noMesin}`, 147, 50);
    doc.text(`Harga Tunai`, 15, 55);
    doc.text(`: ${hargaTunai.toLocaleString("en-US")}`, 43, 55);
    doc.text(`Nopol`, 120, 55);
    doc.text(`: ${nopol}`, 147, 55);
    doc.text(`Uang Muka`, 15, 60);
    doc.text(`: ${uangMuka.toLocaleString("en-US")}`, 43, 60);
    doc.text(`No. Kontrak`, 120, 60);
    doc.text(`: ${noJual}`, 147, 60);
    doc.text(`Angs / Bulan`, 15, 65);
    doc.text(`: ${angPerBulan.toLocaleString("en-US")} / ${tenor}`, 43, 65);
    doc.text(`Tgl. Angs. I`, 120, 65);
    doc.text(`: ${tglAngText}`, 147, 65);
    doc.text(`Jml. Piutang`, 15, 70);
    doc.text(`: ${jumlahPiutang.toLocaleString("en-US")} / ${tenor}`, 43, 70);
    doc.text(`Tgl. Angs. Akhir`, 120, 70);
    doc.text(`: ${tglAngAkhirText}`, 147, 70);
    doc.text(`Thn / Warna`, 15, 75);
    doc.text(`: ${tahun} / ${namaWarna}`, 43, 75);
    doc.text(`Modal`, 15, 80);
    doc.text(`: ${modal.toLocaleString("en-US")}`, 43, 80);
    doc.text(`Bunga`, 15, 85);
    doc.text(`: ${bunga.toLocaleString("en-US")}`, 43, 85);
    doc.text(
      `Dicetak Oleh: ${user.username} | Tanggal : ${current_date} | Jam : ${current_time}`,
      15,
      200
    );
    doc.setFontSize(12);
    function alignCol(data) {
      var col = data.column.index;
      if (col >= 4 && col <= 9) {
        data.cell.styles.halign = "right";
      }
    }
    doc.autoTable({
      startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : 90,
      columns: columnsRinci.map((col) => ({ ...col, dataKey: col.field })),
      body: angsuranTableRinci,
      headStyles: {
        fillColor: [117, 117, 117],
        color: [0, 0, 0],
      },
      didParseCell: function (cell, data) {
        alignCol(cell, data);
      },
    });
    window.open(URL.createObjectURL(doc.output("blob")));
  };

  const downloadPdfHistory = async () => {
    setLoading(true);
    const allAngsuransByNoJualHistory = await axios.post(
      `${tempUrl}/angsuransByNoJualHistory`,
      {
        dendaPersenSetting: setting.dendaPersenSetting,
        perTgl,
        noJual: id,
        id: user._id,
        token: user.token,
        kodeCabang: user.cabang._id,
      }
    );
    let angsuranTableHistory = allAngsuransByNoJualHistory.data;

    var date = new Date();
    var current_date = formatDate(date);
    var current_time = formatTime(date);
    const doc = new jsPDF();
    doc.setFontSize(12);
    doc.text(`${user.cabang.istilahCabang}`, 15, 10);
    doc.setFontSize(16);
    doc.text(`Penerimaan Angsuran`, 75, 30);
    doc.setFontSize(10);

    let tempY = 40;
    let tempX1 = 15;
    let tempX2 = 43;
    let tempX3 = 120;
    let tempX4 = 147;

    doc.text(`Nama`, tempX1, tempY);
    doc.text(`: ${namaRegister}`, tempX2, tempY);
    doc.text(`Type`, tempX3, tempY);
    doc.text(`: ${tipe}`, tempX4, tempY);
    tempY += 5;
    doc.text(`Alamat`, tempX1, tempY);
    doc.text(`: ${almRegister.substring(0, 30)}`, tempX2, tempY);
    doc.text(`Jenis`, tempX3, tempY);
    doc.text(`: SP. MOTOR`, tempX4, tempY);
    tempY += 5;
    doc.text(`No. / Tgl.kontrak`, tempX1, tempY);
    doc.text(`: ${tanggalJualText}`, tempX2, tempY);
    doc.text(`No. Chassis`, tempX3, tempY);
    doc.text(`: ${noRangka}`, tempX4, tempY);
    tempY += 5;
    doc.text(`Harga Kontan`, tempX1, tempY);
    doc.text(`: ${hargaTunai.toLocaleString("en-US")}`, tempX2, tempY);
    doc.text(`No. Engine`, tempX3, tempY);
    doc.text(`: ${noMesin}`, tempX4, tempY);
    tempY += 5;
    doc.text(`Uang Muka`, tempX1, tempY);
    doc.text(`: ${uangMuka.toLocaleString("en-US")}`, tempX2, tempY);
    doc.text(`No. STNK`, tempX3, tempY);
    doc.text(`: ${nopol}`, tempX4, tempY);
    tempY += 5;
    doc.text(`Angs / Bulan`, tempX1, tempY);
    doc.text(
      `: ${angPerBulan.toLocaleString("en-US")} x ${tenor}`,
      tempX2,
      tempY
    );
    doc.text(`KONTRAK`, tempX3, tempY);
    doc.text(`: ${noJual}`, tempX4, tempY);
    tempY += 5;
    doc.text(`Jumlah Piutang`, tempX1, tempY);
    doc.text(
      `: ${jumlahPiutang.toLocaleString("en-US")} x ${tenor}`,
      tempX2,
      tempY
    );
    doc.text(`Tgl. Angs. I`, tempX3, tempY);
    doc.text(`: ${tglAngText}`, tempX4, tempY);
    tempY += 5;
    doc.text(`Perakitan / Warna`, tempX1, tempY);
    doc.text(`: ${tahun} / ${namaWarna}`, tempX2, tempY);
    doc.text(`Tgl. Angs. Akhir`, tempX3, tempY);
    doc.text(`: ${tglAngAkhirText}`, tempX4, tempY);
    doc.text(
      `Dicetak Oleh: ${user.username} | Tanggal : ${current_date} | Jam : ${current_time}`,
      15,
      290
    );
    doc.setFontSize(12);
    function alignCol(data) {
      var col = data.column.index;
      if (col >= 4 && col <= 9) {
        data.cell.styles.halign = "right";
      }
    }
    let tempHeight = 35;
    doc.autoTable({
      startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : 80,
      columns: columnsHistory.map((col) => ({ ...col, dataKey: col.field })),
      body: angsuranTableHistory,
      headStyles: {
        fillColor: [117, 117, 117],
        color: [0, 0, 0],
      },
      didParseCell: function (cell, data) {
        alignCol(cell, data);
      },
      didDrawPage: (d) => {
        tempHeight = d.cursor.y;
      },
    });

    let tempSisaPiutangAngsuran = jumlahPiutang;
    let tempHutangDenda = 0;
    let tempDenda = 0;
    for (let i = 0; i < angsuranTableHistory.length; i++) {
      // Find Last Hutang Denda
      if (i > 0) {
        if (angsuranTableHistory[i].noKwitansi === "") {
          tempHutangDenda = angsuranTableHistory[i - 1].hutangDendaNumber;
          break;
        }
      }

      // Find Hutang Denda terakhir jika sudah lunas
      if (
        i + 1 === angsuranTableHistory.length &&
        angsuranTableHistory[i].noKwitansi === ""
      ) {
        tempHutangDenda = angsuranTableHistory[i].hutangDendaNumber;
        break;
      }
    }
    for (let angsuran of angsuranTableHistory) {
      if (angsuran.dendaNumber > 0) {
        if (angsuran.bayarNumber === 0) {
          tempDenda += angsuran.dendaNumber;
        }
      }
    }
    for (let i = 0; i < angsuranTableHistory.length; i++) {
      if (angsuranTableHistory[i].noKwitansi === "") {
        if (i === 0) {
          break;
        }
        if (i > 0) {
          tempSisaPiutangAngsuran = angsuranTableHistory[i - 1].saldoNumber;
          break;
        }
      }

      if (
        i === angsuranTableHistory.length - 1 &&
        angsuranTableHistory[i].noKwitansi !== ""
      ) {
        tempSisaPiutangAngsuran = 0;
        tempHutangDenda = 0;
        tempDenda = 0;
      }
    }
    tempHeight += 10;
    doc.setFontSize(10);
    doc.text(`Sisa Piutang Angsuran`, 120, tempHeight);
    doc.text(`:`, 160, tempHeight);
    doc.text(
      `${tempSisaPiutangAngsuran.toLocaleString("en-US")}`,
      192,
      tempHeight,
      {
        align: "right",
      }
    );
    tempHeight += 5;
    doc.text(`Hutang Denda`, 120, tempHeight);
    doc.text(`:`, 160, tempHeight);
    doc.text(`${tempHutangDenda.toLocaleString("en-US")}`, 192, tempHeight, {
      align: "right",
    });
    tempHeight += 5;
    doc.text(`Denda Per`, 120, tempHeight);
    doc.text(`:`, 160, tempHeight);
    doc.text(`${tempDenda.toLocaleString("en-US")}`, 192, tempHeight, {
      align: "right",
    });
    tempHeight += 5;
    doc.text(`Total Piutang`, 120, tempHeight);
    doc.text(`:`, 160, tempHeight);
    doc.text(
      `${(tempSisaPiutangAngsuran + tempHutangDenda + tempDenda).toLocaleString(
        "en-US"
      )}`,
      192,
      tempHeight,
      {
        align: "right",
      }
    );

    setLoading(false);
    window.open(URL.createObjectURL(doc.output("blob")));
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Button
        variant="outlined"
        color="secondary"
        onClick={() => navigate("/daftarAngsuran")}
        sx={{ marginLeft: 2, marginTop: 4, marginBottom: 2 }}
      >
        {"< Kembali"}
      </Button>
      <Typography color="#757575">Piutang</Typography>
      <Typography variant="h4" sx={subTitleText}>
        Angsuran
      </Typography>
      <Box sx={downloadButtons}>
        <ButtonGroup variant="outlined" color="secondary">
          <Button startIcon={<PrintIcon />} onClick={() => downloadPdfRekap()}>
            Rekap
          </Button>
          {user.tipeUser === "MGR" && (
            <Button
              startIcon={<DownloadIcon />}
              onClick={() => downloadPdfRinci()}
            >
              Rinci
            </Button>
          )}
          <Button
            variant="contained"
            onClick={() => setToggleHistory(!toggleHistory)}
          >
            History
          </Button>
        </ButtonGroup>
      </Box>
      {toggleHistory && (
        <>
          <Box sx={spacingTop}>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Per Tanggal :
                  </Form.Label>
                  <Col sm="5">
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      selected={perTgl}
                      onChange={(e) => setPerTgl(e)}
                      customInput={
                        <TextField sx={{ width: "100%" }} size="small" />
                      }
                    />
                  </Col>
                  <Col sm="4">
                    <Button
                      variant="contained"
                      startIcon={<PrintIcon />}
                      onClick={() => {
                        if (perTgl === null) {
                          alert("Input Per Tanggal harus diisi!");
                        } else {
                          downloadPdfHistory();
                        }
                      }}
                    >
                      CETAK
                    </Button>
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          </Box>
          <hr />
        </>
      )}
      <Box sx={downloadButtons}>
        <ButtonGroup variant="contained">
          <Button
            color="primary"
            startIcon={<EditIcon />}
            sx={{ textTransform: "none" }}
            onClick={() => {
              navigate(`/customer/${customerId}`);
            }}
          >
            Ubah Foto
          </Button>
          <Button
            color="warning"
            startIcon={<EditIcon />}
            sx={{ textTransform: "none" }}
            onClick={() => {
              navigate(`/daftarAngsuran/angsuran/${id}/ubahSurveyor/${jualId}`);
            }}
          >
            Ubah Surveyor
          </Button>
        </ButtonGroup>
      </Box>
      <Box>
        <Divider sx={[dividerStyle, { marginBottom: 2 }]} />
        <Box sx={textFieldContainer}>
          <Box sx={textFieldWrapper}>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Nama Register :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control value={namaRegister} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    No. Jual :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control value={noJual} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Tanggal Jual :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control value={tanggalJual} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Nopol :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control value={nopol} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Tipe :
                  </Form.Label>
                  <Col sm="5">
                    <Form.Control value={tipe} disabled />
                  </Col>
                  <Col sm="4">
                    <Form.Control value={namaTipe} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Alamat :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control value={almRegister} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Telepon :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control value={tlpRegister} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          </Box>
          <Box sx={[textFieldWrapper, secondWrapper]}>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    SP Ke- :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control value={spKe} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Tgl. MD Terakhir :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control value={tglSpTerakhir} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Tgl. ST Terakhir :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control value={tglStTerakhir} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    SP Ke- :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control value={spKe} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Tgl. Penarikan :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control value={tglPenarikan} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Surveyor :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control
                      value={`${kodeSurveyor.kodeSurveyor} - ${kodeSurveyor.namaSurveyor}`}
                      disabled
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Surveyor Asli :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control
                      value={`${kodeSurveyorAsli.kodeSurveyor} - ${kodeSurveyorAsli.namaSurveyor}`}
                      disabled
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Marketing :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control
                      value={`${kodeMarketing.kodeMarketing} - ${kodeMarketing.namaMarketing}`}
                      disabled
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          </Box>
        </Box>
        <Divider sx={dividerStyle} />
        <Chip
          label="Percepatan"
          sx={{
            backgroundColor: Colors.blue100,
          }}
        />
        <Chip
          label="Percepatan Dibayar dari Cabang Lain"
          sx={{
            backgroundColor: Colors.green400,
            ml: 1,
          }}
        />
        <Chip
          label="Dibayar dari Cabang Lain"
          sx={{
            backgroundColor: Colors.pink300,
            ml: 1,
          }}
        />
        <Chip
          label="Penarikan"
          sx={{
            backgroundColor: Colors.yellow400,
            ml: 1,
          }}
        />
        <Box sx={tableContainer}>
          <ShowTableAngsuran id={id} currentPosts={currentPosts} />
        </Box>
        <Box sx={tableContainer}>
          <Pagination
            count={count}
            page={page}
            onChange={handleChange}
            color="primary"
            size={screenSize <= 600 ? "small" : "large"}
          />
        </Box>
      </Box>
    </>
  );
};

export default TampilAngsuran;

const subTitleText = {
  fontWeight: "900",
};

const dividerStyle = {
  pt: 4,
};

const textFieldContainer = {
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "row",
  },
};

const textFieldWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  maxWidth: {
    md: "40vw",
  },
};

const tableContainer = {
  pt: 4,
  display: "flex",
  justifyContent: "center",
};

const labelInput = {
  fontWeight: "600",
  marginLeft: 1,
};

const spacingTop = {
  mt: 4,
};

const secondWrapper = {
  marginLeft: {
    sm: 4,
  },
  marginTop: {
    sm: 0,
    xs: 4,
  },
};

const downloadButtons = {
  mt: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};
