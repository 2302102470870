import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { formatDate } from "../../../constants/helper";
import { tempUrl } from "../../../contexts/ContextProvider";
import { useStateContext } from "../../../contexts/ContextProvider";
import { AuthContext } from "../../../contexts/AuthContext";
import { ShowTableMotorHilang } from "../../../components/ShowTable";
import { FetchErrorHandling } from "../../../components/FetchErrorHandling";
import {
  SearchBar,
  Loader,
  ButtonModifier,
  ScrollToTop,
} from "../../../components";
import { Colors } from "../../../constants/styles";
import {
  Box,
  TextField,
  Typography,
  Divider,
  Pagination,
  Chip,
  ButtonGroup,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Card,
  CardHeader,
  CardActionArea,
  CardMedia,
} from "@mui/material";
import jsPDF from "jspdf";
import "jspdf-autotable";
import PrintIcon from "@mui/icons-material/Print";
import { Col, Row, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Carousel from "react-elastic-carousel";

const TampilMotorHilang = () => {
  const { setting, user } = useContext(AuthContext);
  const { dispatch } = useContext(AuthContext);
  const location = useLocation();
  const id = location.pathname.split("/")[2];
  const { screenSize } = useStateContext();
  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };
  const [isFetchError, setIsFetchError] = useState(false);
  const [isMotorHilangsExist, setIsMotorHilangsExist] = useState(false);
  const [noMotorHilang, setNoMotorHilang] = useState("");
  const [noJual, setNoJual] = useState("");
  const [tglMotorHilang, setTglMotorHilang] = useState("");
  const [nopol, setNopol] = useState("");
  const [merk, setMerk] = useState("");
  const [tahun, setTahun] = useState("");
  const [noRangka, setNoRangka] = useState("");
  const [noMesin, setNoMesin] = useState("");
  const [namaWarna, setNamaWarna] = useState("");
  const [namaStnk, setNamaStnk] = useState("");
  const [alamatStnk, setAlamatStnk] = useState("");
  const [namaRegister, setNamaRegister] = useState("");
  const [almRegister, setAlmRegister] = useState("");
  const [tunggakan, setTunggakan] = useState("");
  const [keterangan, setKeterangan] = useState("");
  const [kodeMarketing, setKodeMarketing] = useState("");
  const [kodeSurveyor, setKodeSurveyor] = useState("");
  const [gambar, setGambar] = useState([]);

  const [value, setValue] = useState("Semua");
  const [searchTerm, setSearchTerm] = useState("");
  const [motorHilangsData, setMotorHilangsData] = useState([]);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  let [page, setPage] = useState(0);
  const [limit, setLimit] = useState(20);
  const [pages, setPages] = useState(0);
  const [rows, setRows] = useState(0);
  const [query, setQuery] = useState("");

  const [dariTgl, setDariTgl] = useState(new Date());
  const [sampaiTgl, setSampaiTgl] = useState(new Date());
  const [toggleCetak, setToggleCetak] = useState(false);

  const columnsCetak = [
    { title: "No.", field: "no" },
    { title: "Tgl", field: "tglMotorHilangFormatted" },
    { title: "Nopol", field: "nopol" },
    { title: "Merk", field: "merk" },
    { title: "Type", field: "tipe" },
    { title: "Tahun", field: "tahun" },
    { title: "No Rangka", field: "noRangka" },
    { title: "No Mesin", field: "noMesin" },
    { title: "Warna", field: "namaWarna" },
    { title: "Nama Stnk", field: "namaStnk" },
    { title: "Alamat Stnk", field: "alamatStnk" },
    { title: "Nama Pemohon", field: "namaRegister" },
    { title: "Alamat Tagih", field: "almRegister" },
    { title: "Tunggak", field: "tunggakan" },
    { title: "Surveyor", field: "namaSurveyor" },
    { title: "Marketing", field: "namaMarketing" },
    { title: "Ket.", field: "noJual" },
    { title: "Ket", field: "keterangan" },
  ];

  const handleChange = (e, p) => {
    setPage(p - 1);
  };

  const handleChangeFilter = (event) => {
    setValue(event.target.value);
  };

  const searchData = (e) => {
    e.preventDefault();
    setPage(0);
    setSearchTerm(query);
  };

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 3 },
    { width: 768, itemsToShow: 4 },
    { width: 1200, itemsToShow: 5 },
  ];

  useEffect(() => {
    id ? getMotorHilangById() : setIsMotorHilangsExist(false);
  }, [id]);

  useEffect(() => {
    getMotorHilangsData();
  }, [value, page, searchTerm]);

  const getMotorHilangsData = async () => {
    setLoading(true);
    try {
      let allMotorHilangs;
      switch (value) {
        case "Penarikan":
          allMotorHilangs = await axios.post(
            `${tempUrl}/motorHilangsPaginationPenarikan?search_query=${searchTerm}&page=${page}&limit=${limit}`,
            {
              id: user._id,
              token: user.token,
              kodeCabang: user.cabang._id,
            }
          );
          break;
        case "MotorHilang":
          allMotorHilangs = await axios.post(
            `${tempUrl}/motorHilangsPaginationNoPenarikan?search_query=${searchTerm}&page=${page}&limit=${limit}`,
            {
              id: user._id,
              token: user.token,
              kodeCabang: user.cabang._id,
            }
          );
          break;
        case "Sudah Bayar":
          allMotorHilangs = await axios.post(
            `${tempUrl}/motorHilangsPaginationSudahBayar?search_query=${searchTerm}&page=${page}&limit=${limit}`,
            {
              id: user._id,
              token: user.token,
              kodeCabang: user.cabang._id,
            }
          );
          break;
        default:
          allMotorHilangs = await axios.post(
            `${tempUrl}/motorHilangsPagination?search_query=${searchTerm}&page=${page}&limit=${limit}`,
            {
              id: user._id,
              token: user.token,
              kodeCabang: user.cabang._id,
            }
          );
          break;
      }

      setQuery(searchTerm);
      setMotorHilangsData(allMotorHilangs.data.motorHilangs);
      setPage(allMotorHilangs.data.page);
      setPages(allMotorHilangs.data.totalPage);
      setRows(allMotorHilangs.data.totalRows);
    } catch (error) {
      if (error.response.status == 401) {
        dispatch({ type: "LOGOUT" });
        navigate("/");
      }
      setIsFetchError(true);
    }
    setLoading(false);
  };

  const getMotorHilangById = async () => {
    if (id) {
      const response = await axios.post(`${tempUrl}/motorHilangs/${id}`, {
        id: user._id,
        token: user.token,
      });
      setIsMotorHilangsExist(true);
      setNoMotorHilang(response.data.noMotorHilang);
      setNoJual(response.data.noJual);
      setTglMotorHilang(formatDate(response.data.tglMotorHilang));
      setNopol(response.data.nopol);
      setMerk(response.data.merk);
      setTahun(response.data.tahun);
      setNoRangka(response.data.noRangka);
      setNoMesin(response.data.noMesin);
      setNamaWarna(response.data.namaWarna);
      setNamaStnk(response.data.namaStnk);
      setAlamatStnk(response.data.alamatStnk);
      setMerk(response.data.merk);
      setNamaRegister(response.data.namaRegister);
      setAlmRegister(response.data.almRegister);
      setTunggakan(response.data.tunggakan);
      setKeterangan(response.data.keterangan);
      setKodeMarketing(
        `${response.data.idJual.kodeMarketing.kodeMarketing} - ${response.data.idJual.kodeMarketing.namaMarketing}`
      );
      setKodeSurveyor(
        `${response.data.idJual.kodeSurveyor.kodeSurveyor} - ${response.data.idJual.kodeSurveyor.namaSurveyor}`
      );
      setGambar(response.data.idJual.gambar);
    }
  };

  const deleteMotorHilang = async (id) => {
    try {
      setLoading(true);

      // Find Jual
      const findJualByNoJual = await axios.post(`${tempUrl}/jualByNoJual`, {
        noJual,
        id: user._id,
        token: user.token,
        kodeCabang: user.cabang._id,
      });

      // Delete Motor Hilang Transaction
      await axios.post(`${tempUrl}/deleteMotorHilangTransaction`, {
        motorHilangData: {
          idMotorHilang: id,
          id: user._id,
          token: user.token,
        },
        jualData: {
          idJual: findJualByNoJual.data._id,
          isMotorHilang: false,
          kodeCabang: user.cabang._id,
          id: user._id,
          token: user.token,
        },
        id: user._id,
        token: user.token,
      });

      setNoMotorHilang("");
      setNoJual("");
      setTglMotorHilang("");
      setNopol("");
      setMerk("");
      setTahun("");
      setNoRangka("");
      setNoMesin("");
      setNamaWarna("");
      setNamaStnk("");
      setAlamatStnk("");
      setMerk("");
      setNamaRegister("");
      setAlmRegister("");
      setTunggakan("");
      setKeterangan("");
      setLoading(false);
      navigate("/motorHilang");
      getMotorHilangsData();
    } catch (error) {
      console.log(error);
    }
  };

  const downloadPdfCetak = async () => {
    setLoading(true);
    let allMotorHilangs;
    switch (value) {
      case "Penarikan":
        allMotorHilangs = await axios.post(
          `${tempUrl}/motorHilangsPenarikanTanggal`,
          {
            dariTgl,
            sampaiTgl,
            id: user._id,
            token: user.token,
            kodeCabang: user.cabang._id,
          }
        );
        break;
      case "MotorHilang":
        allMotorHilangs = await axios.post(
          `${tempUrl}/motorHilangsNoPenarikanTanggal`,
          {
            dariTgl,
            sampaiTgl,
            id: user._id,
            token: user.token,
            kodeCabang: user.cabang._id,
          }
        );
        break;
      default:
        allMotorHilangs = await axios.post(`${tempUrl}/motorHilangsTanggal`, {
          dariTgl,
          sampaiTgl,
          id: user._id,
          token: user.token,
          kodeCabang: user.cabang._id,
        });
        break;
    }

    const doc = new jsPDF("l", "mm", [297, 210]);
    doc.setFontSize(12);
    doc.text(`${user.cabang.istilahCabang}`, 15, 10);
    doc.setFontSize(16);
    doc.text(`Rekap Tunggakan / MotorHilang`, 120, 30);
    doc.setFontSize(10);

    let no = 0;
    doc.autoTable({
      startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : 45,
      columns: columnsCetak.map((col) => ({ ...col, dataKey: col.field })),
      body: allMotorHilangs.data.map((col) => {
        no++;
        return { ...col, no };
      }),
      headStyles: {
        fillColor: [117, 117, 117],
        color: [0, 0, 0],
      },
      styles: {
        fontSize: 5,
      },
    });

    doc.setFontSize(12);
    window.open(URL.createObjectURL(doc.output("blob")));
    setLoading(false);
  };

  const downloadPdf = async () => {
    const doc = new jsPDF();
    doc.setFontSize(12);
    doc.text(`${user.cabang.istilahCabang}`, 15, 10);
    doc.setFontSize(16);
    doc.text(`Motor Hilang Motor`, 95, 30);
    doc.setFontSize(10);

    let tempY = 40;
    let tempX1 = 15;
    let tempX2 = 43;

    doc.text(`No`, tempX1, tempY);
    doc.text(`: ${noMotorHilang}`, tempX2, tempY);
    tempY += 5;
    doc.text(`No Kontrak`, tempX1, tempY);
    doc.text(`: ${noJual}`, tempX2, tempY);
    tempY += 5;
    doc.text(`Tgl.`, tempX1, tempY);
    doc.text(`: ${tglMotorHilang}`, tempX2, tempY);
    tempY += 5;
    doc.text(`No Polisi`, tempX1, tempY);
    doc.text(`: ${nopol}`, tempX2, tempY);
    tempY += 5;
    doc.text(`Merk`, tempX1, tempY);
    doc.text(`: ${merk}`, tempX2, tempY);
    tempY += 5;
    doc.text(`Tahun`, tempX1, tempY);
    doc.text(`: ${tahun}`, tempX2, tempY);
    tempY += 5;
    doc.text(`No Rangka`, tempX1, tempY);
    doc.text(`: ${noRangka}`, tempX2, tempY);
    tempY += 5;
    doc.text(`No Mesin`, tempX1, tempY);
    doc.text(`: ${noMesin}`, tempX2, tempY);
    tempY += 5;
    doc.text(`Nama Warna`, tempX1, tempY);
    doc.text(`: ${namaWarna}`, tempX2, tempY);
    tempY += 5;
    doc.text(`Nama STNK`, tempX1, tempY);
    doc.text(`: ${namaStnk}`, tempX2, tempY);
    tempY += 5;
    doc.text(`Alamat STNK`, tempX1, tempY);
    doc.text(`: ${alamatStnk}`, tempX2, tempY);
    tempY += 5;
    doc.text(`Nama Pemohon`, tempX1, tempY);
    doc.text(`: ${namaRegister}`, tempX2, tempY);
    tempY += 5;
    doc.text(`Alamat Tagih`, tempX1, tempY);
    doc.text(`: ${almRegister}`, tempX2, tempY);
    tempY += 5;
    doc.text(`Tunggakan`, tempX1, tempY);
    doc.text(`: ${tunggakan}`, tempX2, tempY);
    tempY += 5;
    doc.text(`Keterangan`, tempX1, tempY);
    doc.text(`: ${keterangan}`, tempX2, tempY);
    tempY += 5;
    doc.text(`Marketing`, tempX1, tempY);
    doc.text(`: ${kodeMarketing}`, tempX2, tempY);
    tempY += 5;
    doc.text(`Surveyor`, tempX1, tempY);
    doc.text(`: ${kodeSurveyor}`, tempX2, tempY);

    doc.setFontSize(12);
    window.open(URL.createObjectURL(doc.output("blob")));
  };

  if (loading) {
    return <Loader />;
  }

  if (isFetchError) {
    return <FetchErrorHandling />;
  }

  return (
    <Box>
      <ScrollToTop />
      <Typography color="#757575">Piutang</Typography>
      <Typography variant="h4" sx={subTitleText}>
        Motor Hilang
      </Typography>
      <Box sx={downloadButtons}>
        <ButtonGroup variant="outlined" color="secondary">
          {isMotorHilangsExist && (
            <Button startIcon={<PrintIcon />} onClick={() => downloadPdf()}>
              CETAK
            </Button>
          )}
          <Button
            variant="contained"
            onClick={() => setToggleCetak(!toggleCetak)}
          >
            Cetak
          </Button>
        </ButtonGroup>
      </Box>
      {toggleCetak && (
        <>
          <Box sx={spacingTop}>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Dari Tanggal :
                  </Form.Label>
                  <Col sm="5">
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      selected={dariTgl}
                      onChange={(e) => setDariTgl(e)}
                      customInput={
                        <TextField sx={{ width: "100%" }} size="small" />
                      }
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Sampai Tanggal :
                  </Form.Label>
                  <Col sm="5">
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      selected={sampaiTgl}
                      onChange={(e) => setSampaiTgl(e)}
                      customInput={
                        <TextField sx={{ width: "100%" }} size="small" />
                      }
                    />
                  </Col>
                  <Col sm="4">
                    <Button
                      variant="contained"
                      startIcon={<PrintIcon />}
                      onClick={() => {
                        if (dariTgl === null || sampaiTgl == null) {
                          alert("Input Tanggal harus diisi!");
                        } else {
                          downloadPdfCetak();
                        }
                      }}
                    >
                      CETAK
                    </Button>
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          </Box>
          <hr />
        </>
      )}
      <Box sx={buttonModifierContainer}>
        <ButtonModifier
          id={id}
          kode={user.tipeUser === "MGR" && isMotorHilangsExist}
          addLink={`/motorHilang/tambahMotorHilang`}
          editLink={`/motorHilang/${id}/edit`}
          deleteUser={deleteMotorHilang}
          nameUser={noJual}
        />
      </Box>
      <FormControl sx={{ marginTop: 1 }}>
        <FormLabel id="demo-controlled-radio-buttons-group">Filter</FormLabel>
        <RadioGroup
          row
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          defaultValue="Semua"
          value={value}
          onChange={handleChangeFilter}
        >
          <FormControlLabel
            value="Penarikan"
            control={<Radio />}
            label="Penarikan"
          />
          <FormControlLabel
            value="MotorHilang"
            control={<Radio />}
            label="Motor Hilang"
          />
          <FormControlLabel
            value="Sudah Bayar"
            control={<Radio />}
            label="Sudah Bayar"
          />
          <FormControlLabel value="Semua" control={<Radio />} label="Semua" />
        </RadioGroup>
      </FormControl>
      <Divider sx={dividerStyle} />
      {isMotorHilangsExist && gambar.length !== 0 && (
        <Carousel breakPoints={breakPoints} sx={carouselStyle}>
          {gambar.length !== 0 &&
            gambar.map((img, i) => (
              <Card sx={carouselCard} elevation={10}>
                <CardActionArea>
                  <CardHeader title={`Gambar`} subheader={`No. ${i + 1}`} />
                  <CardMedia
                    component="img"
                    height="100%"
                    src={img}
                    alt={noMotorHilang}
                    sx={oldImageCardMedia}
                    onClick={() => {
                      window.open(img, "_blank", "noopener,noreferrer");
                    }}
                  />
                </CardActionArea>
              </Card>
            ))}
        </Carousel>
      )}
      {isMotorHilangsExist && (
        <>
          <Box sx={showDataContainer}>
            <Box sx={showDataWrapper}>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      No. Motor Hilang :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={noMotorHilang} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      No. Jual :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={noJual} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Tgl. Motor Hilang :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={tglMotorHilang} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Nopol :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={nopol} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Merk :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={merk} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Tahun :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={tahun} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      No. Rangka :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={noRangka} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      No. Mesin :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={noMesin} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Nama Warna :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={namaWarna} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Box>
            <Box sx={[showDataWrapper, secondWrapper]}>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Nama STNK :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={namaStnk} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Alamat STNK :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={alamatStnk} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Nama Pemohon :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={namaRegister} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Alamat Tagih :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={almRegister} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Tunggakan :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={`${tunggakan.toLocaleString("en-US")} hari`}
                        disabled
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Marketing :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={kodeMarketing} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Surveyor :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={kodeSurveyor} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Keterangan :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        as="textarea"
                        rows={3}
                        value={keterangan}
                        disabled
                        readOnly
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Box>
          </Box>
          <Divider sx={dividerStyle} />
        </>
      )}
      <Form onSubmit={searchData}>
        <Box sx={searchBarContainer}>
          <SearchBar value={query} setSearchTerm={setQuery} />
          <Button
            variant="contained"
            type="submit"
            color="primary"
            disableElevation
          >
            Cari
          </Button>
        </Box>
      </Form>
      <Box sx={{ mt: 2 }}>
        <Chip
          label="Penarikan"
          sx={{
            backgroundColor: Colors.yellow400,
          }}
        />
        <Chip
          label="Sudah Bayar"
          sx={{
            backgroundColor: Colors.red400,
          }}
        />
      </Box>
      <Box sx={tableContainer}>
        <ShowTableMotorHilang currentPosts={motorHilangsData} />
      </Box>
      <Box sx={tableContainer}>
        <Pagination
          count={pages}
          page={page + 1}
          onChange={handleChange}
          color="primary"
          size={screenSize <= 600 ? "small" : "large"}
        />
      </Box>
    </Box>
  );
};

export default TampilMotorHilang;

const subTitleText = {
  fontWeight: "900",
};

const buttonModifierContainer = {
  mt: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};

const dividerStyle = {
  pt: 4,
};

const showDataContainer = {
  mt: 4,
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "row",
  },
};

const showDataWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  maxWidth: {
    md: "40vw",
  },
};

const searchBarContainer = {
  pt: 6,
  display: "flex",
  justifyContent: "center",
};

const tableContainer = {
  pt: 4,
  display: "flex",
  justifyContent: "center",
};

const labelInput = {
  fontWeight: "600",
  marginLeft: 1,
};

const spacingTop = {
  mt: 4,
};

const secondWrapper = {
  marginLeft: {
    sm: 4,
  },
  marginTop: {
    sm: 0,
    xs: 4,
  },
};

const downloadButtons = {
  mt: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};

const carouselStyle = {
  display: "flex",
  height: "200px",
};

const carouselCard = {
  m: "auto",
  mt: 2,
  maxWidth: "500px",
  maxHeight: "500px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "primary",
};

const oldImageCardMedia = {
  display: "flex",
  maxHeight: "150px",
};
