import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { Loader } from "../../../components";
import {
  Box,
  TextField,
  Typography,
  Divider,
  ButtonGroup,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Col, Row, Form } from "react-bootstrap";
import { formatDate } from "../../../constants/helper";

const TampilBeliChild = () => {
  const { user, dispatch } = useContext(AuthContext);
  const { id, idBeliChild } = useParams();
  const navigate = useNavigate();
  const [idStok, setIdStok] = useState("");
  const [noBeli, setNoBeli] = useState("");
  const [kodeTipe, setKodeTipe] = useState("");
  const [namaTipe, setNamaTipe] = useState("");
  const [tahun, setTahun] = useState("");
  const [namaWarna, setNamaWarna] = useState("");
  const [noRangka, setNoRangka] = useState("");
  const [noMesin, setNoMesin] = useState("");
  const [nopol, setNopol] = useState("");
  const [namaStnk, setNamaStnk] = useState("");
  const [alamatStnk, setAlamatStnk] = useState("");
  const [tglStnk, setTglStnk] = useState("");
  const [noBpkb, setNoBpkb] = useState("");
  const [jenisABeli, setJenisABeli] = useState("");
  const [hargaSatuan, setHargaSatuan] = useState("");
  const [ppnABeli, setPpnABeli] = useState("");
  const [tanggalJual, setTanggalJual] = useState("");
  const [noJual, setNoJual] = useState("");
  const [isPost, setIsPost] = useState("");
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  let today = new Date();
  const [isEditable, setIsEditable] = useState(false);
  const { screenSize } = useStateContext();
  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    getUserById();
  }, []);

  const getUserById = async () => {
    if (id) {
      const response = await axios.post(
        `${tempUrl}/daftarStoks/${idBeliChild}`,
        {
          id: user._id,
          token: user.token,
        }
      );
      setIdStok(response.data._id);
      setNoBeli(response.data.noBeli);
      setKodeTipe(response.data.tipe);
      setTahun(response.data.tahun);
      setNamaWarna(response.data.namaWarna);
      setNoRangka(response.data.noRangka);
      setNoMesin(response.data.noMesin);
      setNopol(response.data.nopol);
      setNamaStnk(response.data.namaStnk);
      setAlamatStnk(response.data.alamatStnk);
      setTglStnk(formatDate(response.data.tglStnk));
      setNoBpkb(response.data.noBpkb);
      setJenisABeli(response.data.jenisBeli);
      setHargaSatuan(response.data.hargaSatuan);
      setPpnABeli(response.data.ppnABeli);
      setTanggalJual(formatDate(response.data.tanggalJual));
      setNoJual(response.data.noJual);
      setIsPost(response.data.isPost);
      let tglBeliDate = new Date(response.data.tanggalBeli);

      let isEditableManager =
        user.tipeUser !== "ADM" && response.data.isPost === false;
      let isEditableAdmin =
        user.tipeUser === "ADM" &&
        tglBeliDate?.getFullYear() === today.getFullYear() &&
        tglBeliDate?.getMonth() === today.getMonth() &&
        tglBeliDate?.getDate() === today.getDate() &&
        response.data.isPost === false;

      setIsEditable(isEditableManager || isEditableAdmin);

      if (response.data.noJual !== "") {
        setIsEditable(false);
      }

      const findTipe = await axios.post(`${tempUrl}/tipesByKode`, {
        kodeTipe: response.data.tipe,
        kodeCabang: user.cabang._id,
        id: user._id,
        token: user.token,
      });
      setNamaTipe(findTipe.data.namaTipe);
    }
  };

  const deleteBeliChild = async (id) => {
    try {
      setLoading(true);
      // Get Beli
      const getBeli = await axios.post(`${tempUrl}/belis/${id}`, {
        kodeCabang: user.cabang._id,
        id: user._id,
        token: user.token,
      });
      // Delete Daftar Stok And Update Beli
      await axios.post(`${tempUrl}/deleteDaftarStokAndUpdateBeli`, {
        daftarStokData: {
          idDaftarStok: idStok,
          id: user._id,
          token: user.token,
        },
        beliData: {
          idBeli: id,
          jumlahBeli: parseInt(getBeli.data.jumlahBeli) - parseInt(hargaSatuan),
          kodeCabang: user.cabang._id,
          id: user._id,
          token: user.token,
        },
        kodeCabang: user.cabang._id,
        id: user._id,
        token: user.token,
      });
      setNoBeli("");
      setKodeTipe("");
      setTahun("");
      setNamaWarna("");
      setNoRangka("");
      setNoMesin("");
      setNopol("");
      setNamaStnk("");
      setAlamatStnk("");
      setTglStnk("");
      setNoBpkb("");
      setJenisABeli("");
      setHargaSatuan("");
      setPpnABeli("");
      setTanggalJual("");
      setNoJual("");
      setLoading(false);
      navigate(`/daftarBeli/beli/${id}`);
    } catch (error) {
      console.log(error);
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Button
        variant="outlined"
        color="secondary"
        onClick={() => navigate(`/daftarBeli/beli/${id}`)}
        sx={{ marginLeft: 2, marginTop: 4 }}
      >
        {"< Kembali"}
      </Button>
      <Box>
        <Typography color="#757575">Pembelian</Typography>
        <Typography variant="h4" sx={subTitleText}>
          Barang Beli
        </Typography>
        {isEditable && (
          <Box sx={deleteButtonContainer}>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{`Hapus Data`}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                  {`Yakin ingin menghapus data ${kodeTipe}?`}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => deleteBeliChild(id)}>Ok</Button>
                <Button onClick={handleClose}>Cancel</Button>
              </DialogActions>
            </Dialog>
            <ButtonGroup variant="contained">
              <Button
                color="primary"
                startIcon={<EditIcon />}
                sx={{ textTransform: "none" }}
                onClick={() => {
                  navigate(`/daftarBeli/beli/${id}/${idBeliChild}/edit`);
                }}
              >
                Ubah
              </Button>
              <Button
                color="error"
                startIcon={<DeleteOutlineIcon />}
                sx={{ textTransform: "none" }}
                onClick={handleClickOpen}
              >
                Hapus
              </Button>
            </ButtonGroup>
          </Box>
        )}
        <Divider sx={dividerStyle} />
        <Box sx={[textFieldContainer, spacingTop]}>
          <Box sx={textFieldWrapper}>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    No Beli :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control value={noBeli} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Kode Tipe :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control value={kodeTipe} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Tahun :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control value={tahun} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Nama Warna :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control value={namaWarna} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Nomor Rangka :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control value={noRangka} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    No Mesin :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control value={noMesin} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Nopol :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control value={nopol} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          </Box>
          <Box sx={[textFieldWrapper, secondWrapper]}>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Nama STNK :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control value={namaStnk} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Alamat STNK :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control value={alamatStnk} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Tanggal STNK :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control value={tglStnk} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    No. BPKB :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control value={noBpkb} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Jenis :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control value={jenisABeli} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Harga Satuan :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control
                      value={hargaSatuan.toLocaleString("en-US")}
                      disabled
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    PPN :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control
                      value={ppnABeli.toLocaleString("en-US")}
                      disabled
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Tanggal Jual :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control value={tanggalJual} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    No. Jual :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control value={noJual} disabled />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default TampilBeliChild;

const subTitleText = {
  fontWeight: "900",
};

const deleteButtonContainer = {
  mt: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};

const dividerStyle = {
  pt: 4,
};

const textFieldContainer = {
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "row",
  },
};

const textFieldWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  // maxWidth: {
  //   md: "40vw",
  // },
};

const labelInput = {
  fontWeight: "600",
  marginLeft: 1,
};

const spacingTop = {
  mt: 4,
};

const secondWrapper = {
  marginLeft: {
    sm: 4,
  },
  marginTop: {
    sm: 0,
    xs: 4,
  },
};
