import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { formatDate } from "../../../constants/helper";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl } from "../../../contexts/ContextProvider";
import { useStateContext } from "../../../contexts/ContextProvider";
import { Colors } from "../../../constants/styles";
import { ShowTableBankKeluar } from "../../../components/ShowTable";
import { Loader, usePagination, ButtonModifier } from "../../../components";
import {
  Box,
  TextField,
  Typography,
  Divider,
  Pagination,
  Button,
  TextareaAutosize,
} from "@mui/material";
import { Col, Row, Form } from "react-bootstrap";

const TampilBankKeluar = () => {
  const { user, dispatch } = useContext(AuthContext);
  const location = useLocation();
  const id = location.pathname.split("/")[3];
  const { screenSize } = useStateContext();
  const [noBukti, setNoBukti] = useState("");
  const [tglBankKeluar, setTglBankKeluar] = useState("");
  const [tglBankKeluarDate, setTglBankKeluarDate] = useState();
  const [kodeCOA, setKodeCOA] = useState("");
  const [keterangan, setKeterangan] = useState("");
  const [isPost, setIsPost] = useState("");
  const [jumlah, setJumlah] = useState("");

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  const [bankKeluarsChildData, setBankKeluarsChildData] = useState([]);
  const navigate = useNavigate();
  let today = new Date();
  let isEditableManager = user.tipeUser !== "ADM" && isPost === false;
  let isEditableAdmin =
    user.tipeUser === "ADM" &&
    tglBankKeluarDate?.getFullYear() === today.getFullYear() &&
    tglBankKeluarDate?.getMonth() === today.getMonth() &&
    tglBankKeluarDate?.getDate() === today.getDate() &&
    isPost === false;
  let isEditable = isEditableManager || isEditableAdmin;

  const [loading, setLoading] = useState(false);
  let [page, setPage] = useState(1);
  const PER_PAGE = 20;

  // Get current posts
  const indexOfLastPost = page * PER_PAGE;
  const indexOfFirstPost = indexOfLastPost - PER_PAGE;
  const currentPosts = bankKeluarsChildData.slice(
    indexOfFirstPost,
    indexOfLastPost
  );

  const count = Math.ceil(bankKeluarsChildData.length / PER_PAGE);
  const _DATA = usePagination(bankKeluarsChildData, PER_PAGE);

  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  useEffect(() => {
    id && getBankKeluarById();
  }, [id]);

  const getBankKeluarById = async () => {
    if (id) {
      const response = await axios.post(`${tempUrl}/bankKeluars/${id}`, {
        kodeCabang: user.cabang._id,
        id: user._id,
        token: user.token,
      });
      setNoBukti(response.data.noBukti);
      setTglBankKeluar(formatDate(response.data.tglBankKeluar));
      setTglBankKeluarDate(new Date(response.data.tglBankKeluar));
      setKodeCOA(response.data.COA);
      setKeterangan(response.data.keterangan);
      setIsPost(response.data.isPost);
      setJumlah(response.data.jumlah);
      const response2 = await axios.post(
        `${tempUrl}/bankKeluarsChildByNoBukti`,
        {
          noBukti: response.data.noBukti,
          kodeCabang: user.cabang._id,
          id: user._id,
          token: user.token,
        }
      );
      setBankKeluarsChildData(response2.data);
    }
  };

  const deleteBankKeluar = async (id) => {
    try {
      setLoading(true);
      await axios.post(`${tempUrl}/deleteBankKeluarAndChild`, {
        bankKeluarData: {
          idBankKeluar: id,
          kodeCabang: user.cabang._id,
          id: user._id,
          token: user.token,
        },
        bankKeluarChildData: {
          noBukti,
          kodeCabang: user.cabang._id,
          id: user._id,
          token: user.token,
        },
        kodeCabang: user.cabang._id,
        id: user._id,
        token: user.token,
      });
      setLoading(false);
      navigate("/daftarBankKeluar");
    } catch (error) {
      console.log(error);
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Button
        variant="outlined"
        color="secondary"
        onClick={() => navigate("/daftarBankKeluar")}
        sx={{ marginLeft: 2, marginTop: 4 }}
      >
        {"< Kembali"}
      </Button>
      <Box>
        <Typography color="#757575">Finance</Typography>
        <Typography variant="h4" sx={subTitleText}>
          Bank Keluar
        </Typography>
        {isEditable && (
          <Box sx={buttonModifierContainer}>
            <ButtonModifier
              id={id}
              kode={"test"}
              addLink={`/daftarBankKeluar/bankKeluar/${id}/tambahBankKeluarChild`}
              editLink={`/daftarBankKeluar/bankKeluar/${id}/edit`}
              deleteUser={deleteBankKeluar}
              nameUser={noBukti}
            />
          </Box>
        )}
        <Divider sx={dividerStyle} />
        <Divider sx={{ marginBottom: 2 }} />
        <Box sx={textFieldContainer}>
          <Box sx={textFieldWrapper}>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    No. Bukti :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control required value={noBukti} disabled readOnly />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Tgl. Bank Keluar :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      required
                      value={tglBankKeluar}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Kode COA :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      value={`${kodeCOA.kodeCOA} - ${kodeCOA.namaCOA}`}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Jumlah :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      value={jumlah.toLocaleString("en-US")}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Keterangan :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      as="textarea"
                      rows={3}
                      value={keterangan}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          </Box>
        </Box>
        <Divider sx={dividerStyle} />
        <Box sx={tableContainer}>
          <ShowTableBankKeluar id={id} currentPosts={currentPosts} />
        </Box>
        <Box sx={tableContainer}>
          <Pagination
            count={count}
            page={page}
            onChange={handleChange}
            color="primary"
            size={screenSize <= 600 ? "small" : "large"}
          />
        </Box>
      </Box>
    </>
  );
};

export default TampilBankKeluar;

const subTitleText = {
  fontWeight: "900",
};

const buttonModifierContainer = {
  mt: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};

const dividerStyle = {
  pt: 4,
};

const textFieldContainer = {
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "row",
  },
};

const textFieldWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
};

const tableContainer = {
  pt: 4,
  display: "flex",
  justifyContent: "center",
};

const labelInput = {
  fontWeight: "600",
  marginLeft: 1,
};

const spacingTop = {
  mt: 4,
};

const secondWrapper = {
  marginLeft: {
    sm: 4,
  },
  marginTop: {
    sm: 0,
    xs: 4,
  },
};
