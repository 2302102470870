import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { formatDate } from "../../../constants/helper";
import { tempUrl } from "../../../contexts/ContextProvider";
import { useStateContext } from "../../../contexts/ContextProvider";
import { AuthContext } from "../../../contexts/AuthContext";
import { Colors } from "../../../constants/styles";
import { FetchErrorHandling } from "../../../components/FetchErrorHandling";
import { Loader, ScrollToTop } from "../../../components";
import {
  Paper,
  Box,
  TextField,
  Autocomplete,
  Typography,
  Divider,
  Button,
  Snackbar,
  Alert,
} from "@mui/material";
import { Col, Row, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import EditIcon from "@mui/icons-material/Edit";

const UbahSuratPenarikan = () => {
  const { setting, user } = useContext(AuthContext);
  const { dispatch } = useContext(AuthContext);
  const location = useLocation();
  const id = location.pathname.split("/")[2];
  const { screenSize } = useStateContext();
  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };
  const [isFetchError, setIsFetchError] = useState(false);
  const [noSt, setNoSt] = useState("");
  const [tglStLama, setTglStLama] = useState(new Date());
  const [tglSt, setTglSt] = useState(new Date());
  const [noJual, setNoJual] = useState("");
  const [kodeKolektor, setKodeKolektor] = useState("");
  const [kodeKolektorLama, setKodeKolektorLama] = useState("");
  const [angPerBulan, setAngPerBulan] = useState("");
  const [jmlBlnTelat, setJmlBlnTelat] = useState("");
  const [totalDenda, setTotalDenda] = useState("");
  const [biayaTarik, setBiayaTarik] = useState("");

  const [kolektors, setKolektors] = useState([]);

  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const kolektorOptions = kolektors.map((kolektor) => ({
    label: `${kolektor.kodeKolektor} - ${kolektor.namaKolektor}`,
  }));

  useEffect(() => {
    getKolektor();
    getStById();
  }, [id]);

  const getKolektor = async () => {
    setLoading(true);
    const allKolektors = await axios.post(`${tempUrl}/kolektors`, {
      id: user._id,
      token: user.token,
      kodeCabang: user.cabang._id,
    });
    setKolektors(allKolektors.data);
    setLoading(false);
  };

  const getStById = async () => {
    if (id) {
      const response = await axios.post(`${tempUrl}/sts/${id}`, {
        id: user._id,
        token: user.token,
      });
      setNoSt(response.data.noSt);
      setTglSt(new Date(response.data.tglSt));
      setTglStLama(new Date(response.data.tglSt));
      setNoJual(response.data.noJual);
      setKodeKolektor(
        `${response.data.kodeKolektor.kodeKolektor} - ${response.data.kodeKolektor.namaKolektor}`
      );
      setKodeKolektorLama(
        `${response.data.kodeKolektor.kodeKolektor} - ${response.data.kodeKolektor.namaKolektor}`
      );
      setAngPerBulan(response.data.angPerBulan);
      setJmlBlnTelat(response.data.jmlBlnTelat);
      setTotalDenda(response.data.totalDenda);
      setBiayaTarik(response.data.biayaTarik);
    }
  };

  const updateSuratPenarikan = async (e) => {
    e.preventDefault();
    var date = new Date();
    var current_date =
      date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear();
    var current_time =
      date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();

    let isFailedValidation = kodeKolektor.length === 0 && tglSt === null;
    if (isFailedValidation) {
      setError(true);
      setOpen(!open);
    } else {
      try {
        setLoading(true);
        const tempKolektor = await axios.post(`${tempUrl}/kolektorByKode`, {
          kodeKolektor: kodeKolektor.split(" -", 1)[0],
          id: user._id,
          token: user.token,
          kodeCabang: user.cabang._id,
        });
        // Find Jual
        const response = await axios.post(`${tempUrl}/jualByNoJual`, {
          noJual,
          id: user._id,
          token: user.token,
          kodeCabang: user.cabang._id,
        });
        // Update St Transaction
        await axios.post(`${tempUrl}/updateStTransaction`, {
          jualData: {
            idJual: response.data._id,
            tglStTerakhirLama: tglStLama,
            tglStTerakhir: tglSt,
            kodeCabang: user.cabang._id,
            id: user._id,
            token: user.token,
          },
          stData: {
            idSt: id,
            kodeKolektor: tempKolektor.data._id,
            tglSt,
            tglUpdate: current_date,
            jamUpdate: current_time,
            userUpdate: user.username,
            kodeCabang: user.cabang._id,
            id: user._id,
            token: user.token,
          },
          kodeCabang: user.cabang._id,
          id: user._id,
          token: user.token,
        });
        setLoading(false);
        navigate(`/suratPenarikan/${id}`);
      } catch (error) {
        if (error.response.status == 401) {
          dispatch({ type: "LOGOUT" });
          navigate("/");
        }
        console.log(error);
      }
    }
    setValidated(true);
  };

  if (loading) {
    return <Loader />;
  }

  if (isFetchError) {
    return <FetchErrorHandling />;
  }

  return (
    <Box>
      <ScrollToTop />
      <Typography color="#757575">Piutang</Typography>
      <Typography variant="h4" sx={subTitleText}>
        Ubah Surat Penarikan
      </Typography>
      <Divider sx={dividerStyle} />
      <Paper sx={contentContainer} elevation={12}>
        <Form noValidate validated={validated} onSubmit={updateSuratPenarikan}>
          <Box sx={showDataContainer}>
            <Box sx={showDataWrapper}>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      No. Jual :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={noJual} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      No. Surat Penarikan :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={noSt} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Tgl. Surat Penarikan <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="8">
                      <DatePicker
                        dateFormat="dd/MM/yyyy"
                        selected={tglSt}
                        onChange={(e) => {
                          setTglSt(e);
                        }}
                        customInput={
                          <TextField
                            error={error && tglSt === null && true}
                            helperText={
                              error &&
                              tglSt === null &&
                              "Tgl. Surat Penarikan harus diisi!"
                            }
                            sx={{ width: "100%" }}
                            size="small"
                          />
                        }
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Kode Eksekutor <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="8">
                      <Autocomplete
                        size="small"
                        disablePortal
                        id="combo-box-demo"
                        options={kolektorOptions}
                        renderInput={(params) => (
                          <TextField
                            size="small"
                            error={error && kodeKolektor.length === 0 && true}
                            helperText={
                              error &&
                              kodeKolektor.length === 0 &&
                              "Kode Eksekutor harus diisi!"
                            }
                            {...params}
                          />
                        )}
                        onInputChange={(e, value) => setKodeKolektor(value)}
                        inputValue={kodeKolektor}
                        onChange={(e, value) => setKodeKolektorLama(value)}
                        value={kodeKolektorLama}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Total :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={(biayaTarik + totalDenda).toLocaleString(
                          "en-US"
                        )}
                        disabled
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Box>
            <Box sx={[showDataWrapper, secondWrapper]}>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Jml Bulan Telat :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={`${jmlBlnTelat} bulan`} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Angsuran / Bulan :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={angPerBulan.toLocaleString("en-US")}
                        disabled
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Denda Tunggakan :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={totalDenda.toLocaleString("en-US")}
                        disabled
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Biaya Tarik :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={biayaTarik.toLocaleString("en-US")}
                        disabled
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Box>
          </Box>
          <Box sx={spacingTop}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => navigate(`/suratPenarikan/${id}`)}
              sx={{ marginRight: 2 }}
            >
              {"< Kembali"}
            </Button>
            <Button variant="contained" startIcon={<EditIcon />} type="submit">
              Ubah
            </Button>
          </Box>
        </Form>
      </Paper>
      <Divider sx={dividerStyle} />
      {error && (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={alertBox}>
            Data belum terisi semua!
          </Alert>
        </Snackbar>
      )}
    </Box>
  );
};

export default UbahSuratPenarikan;

const subTitleText = {
  fontWeight: "900",
};

const dividerStyle = {
  pt: 4,
};

const showDataContainer = {
  mt: 4,
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "row",
  },
};

const showDataWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  maxWidth: {
    md: "40vw",
  },
};

const spacingTop = {
  mt: 4,
};

const secondWrapper = {
  marginLeft: {
    sm: 4,
  },
  marginTop: {
    sm: 0,
    xs: 4,
  },
};

const alertBox = {
  width: "100%",
};

const contentContainer = {
  p: {
    sm: 0,
    md: 3,
  },
  pt: {
    sm: 0,
    md: 1,
  },
  mt: {
    sm: 0,
    md: 2,
  },
  backgroundColor: Colors.grey100,
};

const colorRed = {
  color: "red",
};
