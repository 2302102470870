import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { formatDate, formatTime } from "../../../constants/helper";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl } from "../../../contexts/ContextProvider";
import { useStateContext } from "../../../contexts/ContextProvider";
import { ShowTableCabang } from "../../../components/ShowTable";
import { FetchErrorHandling } from "../../../components/FetchErrorHandling";
import {
  SearchBar,
  Loader,
  ButtonModifier,
  ScrollToTop,
} from "../../../components";
import {
  Box,
  TextField,
  Typography,
  Divider,
  Pagination,
  Button,
  ButtonGroup,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";
import DownloadIcon from "@mui/icons-material/Download";
import PrintIcon from "@mui/icons-material/Print";
import { Col, Row, Form } from "react-bootstrap";

const TampilCabang = () => {
  const { user, setting, dispatch } = useContext(AuthContext);
  const location = useLocation();
  const id = location.pathname.split("/")[2];
  const { screenSize } = useStateContext();
  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  const [isFetchError, setIsFetchError] = useState(false);
  const [isCabangExist, setIsCabangExist] = useState(false);
  const [kodeCabang, setKodeCabang] = useState("");
  const [namaCabang, setNamaCabang] = useState("");
  const [alamatCabang, setAlamatCabang] = useState("");
  const [teleponCabang, setTeleponCabang] = useState("");
  const [picCabang, setPicCabang] = useState("");
  const [npwpCabang, setNpwpCabang] = useState("");
  const [istilahCabang, setIstilahCabang] = useState("");
  const [kodeCOA, setKodeCOA] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [cabangsData, setCabangsData] = useState([]);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const columns = [
    { title: "No", field: "no" },
    { title: "Kode", field: "_id" },
    { title: "Nama Cabang", field: "namaCabang" },
    { title: "Alamat", field: "alamatCabang" },
    { title: "Telepon", field: "teleponCabang" },
    { title: "PIC", field: "picCabang" },
    { title: "NPWP", field: "npwpCabang" },
    { title: "Istilah", field: "istilahCabang" },
  ];

  const [loading, setLoading] = useState(false);
  let [page, setPage] = useState(0);
  const [limit, setLimit] = useState(20);
  const [pages, setPages] = useState(0);
  const [rows, setRows] = useState(0);
  const [query, setQuery] = useState("");

  const handleChange = (e, p) => {
    setPage(p - 1);
  };

  const searchData = (e) => {
    e.preventDefault();
    setPage(0);
    setSearchTerm(query);
  };

  useEffect(() => {
    id ? getCabangById() : setIsCabangExist(false);
  }, [id]);

  useEffect(() => {
    getCabangsData();
  }, [page, searchTerm]);

  const getCabangsData = async () => {
    setLoading(true);
    try {
      const allCabangs = await axios.post(
        `${tempUrl}/cabangsPagination?search_query=${searchTerm}&page=${page}&limit=${limit}`,
        {
          id: user._id,
          token: user.token,
        }
      );
      setQuery(searchTerm);
      setCabangsData(allCabangs.data.cabangs);
      setPage(allCabangs.data.page);
      setPages(allCabangs.data.totalPage);
      setRows(allCabangs.data.totalRows);
    } catch (error) {
      if (error.response.status == 401) {
        dispatch({ type: "LOGOUT" });
        navigate("/");
      }
      setIsFetchError(true);
    }
    setLoading(false);
  };

  const getCabangsForDoc = async (isPdf, isExcel) => {
    setLoading(true);
    const allCabangForDoc = await axios.post(`${tempUrl}/cabangsForDoc`, {
      id: user._id,
      token: user.token,
    });
    setLoading(false);
    if (isPdf) {
      downloadPdf(allCabangForDoc.data);
    } else if (isExcel) {
      downloadExcel(allCabangForDoc.data);
    }
  };

  const getCabangById = async () => {
    setLoading(true);
    if (id) {
      const pickedCabang = await axios.post(`${tempUrl}/cabangs/${id}`, {
        id: user._id,
        token: user.token,
      });
      setIsCabangExist(true);
      setKodeCabang(pickedCabang.data._id);
      setNamaCabang(pickedCabang.data.namaCabang);
      setAlamatCabang(pickedCabang.data.alamatCabang);
      setTeleponCabang(pickedCabang.data.teleponCabang);
      setPicCabang(pickedCabang.data.picCabang);
      setNpwpCabang(pickedCabang.data.npwpCabang);
      setIstilahCabang(pickedCabang.data.istilahCabang);
      setKodeCOA(pickedCabang.data.COA);
    }
    setLoading(false);
  };

  const deleteCabang = async (id) => {
    const findBelisCabang = await axios.post(`${tempUrl}/belisCabang`, {
      cabangId: id,
      id: user._id,
      token: user.token,
    });
    if (findBelisCabang.data.length > 0) {
      // There's Record -> Forbid Delete
      handleClickOpen();
    } else {
      // No Record Found -> Delete
      try {
        setLoading(true);
        await axios.post(`${tempUrl}/deleteSettingByCabang`, {
          kodeCabang,
          id: user._id,
          token: user.token,
        });
        await axios.post(`${tempUrl}/deleteCabang/${id}`, {
          id: user._id,
          token: user.token,
        });
        setKodeCabang("");
        setNamaCabang("");
        setAlamatCabang("");
        setTeleponCabang("");
        setPicCabang("");
        setNpwpCabang("");
        setIstilahCabang("");
        setKodeCOA("");
        setLoading(false);
        navigate("/cabang");
        getCabangsData();
      } catch (error) {
        if (error.response.status == 401) {
          dispatch({ type: "LOGOUT" });
          navigate("/");
        }
        console.log(error);
      }
    }
  };

  const downloadPdf = (data) => {
    var date = new Date();
    var current_date = formatDate(date);
    var current_time = formatTime(date);
    const doc = new jsPDF();
    doc.setFontSize(12);
    doc.text(`${setting.namaPerusahaan} - ${setting.kotaPerusahaan}`, 15, 10);
    doc.text(`${setting.lokasiPerusahaan}`, 15, 15);
    doc.setFontSize(16);
    doc.text(`Daftar Cabang`, 90, 30);
    doc.setFontSize(10);
    doc.text(
      `Dicetak Oleh: ${user.username} | Tanggal : ${current_date} | Jam : ${current_time}`,
      15,
      290
    );
    doc.setFontSize(12);
    let no = 0;
    doc.autoTable({
      startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : 45,
      columns: columns.map((col) => ({ ...col, dataKey: col.field })),
      body: data.map((col) => {
        no++;
        return { ...col, no };
      }),
      headStyles: {
        fillColor: [117, 117, 117],
        color: [0, 0, 0],
      },
    });
    window.open(URL.createObjectURL(doc.output("blob")));
  };

  const downloadExcel = (data) => {
    const workSheet = XLSX.utils.json_to_sheet(data);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, `Cabang`);
    // Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    // Binary String
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    // Download
    XLSX.writeFile(workBook, `daftarCabang.xlsx`);
  };

  if (loading) {
    return <Loader />;
  }

  if (isFetchError) {
    return <FetchErrorHandling />;
  }

  return (
    <Box>
      <ScrollToTop />
      <Typography color="#757575">Master</Typography>
      <Typography variant="h4" sx={subTitleText}>
        Cabang
      </Typography>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Tidak bisa dihapus karena sudah ada data!`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {`Nama Cabang data: ${namaCabang}`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
      <Box sx={downloadButtons}>
        <ButtonGroup variant="outlined" color="secondary">
          <Button
            startIcon={<PrintIcon />}
            onClick={() => getCabangsForDoc(true, false)}
          >
            CETAK
          </Button>
          <Button
            startIcon={<DownloadIcon />}
            onClick={() => getCabangsForDoc(false, true)}
          >
            EXCEL
          </Button>
        </ButtonGroup>
      </Box>
      <Box sx={buttonModifierContainer}>
        <ButtonModifier
          id={id}
          kode={isCabangExist}
          addLink={`/cabang/tambahCabang`}
          editLink={`/cabang/${id}/edit`}
          deleteUser={deleteCabang}
          nameUser={kodeCabang}
        />
      </Box>
      <Divider sx={dividerStyle} />
      {isCabangExist && (
        <>
          <Box sx={showDataContainer}>
            <Box sx={showDataWrapper}>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="3" style={textRight}>
                      Kode :
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control value={kodeCabang} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="3" style={textRight}>
                      Nama :
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control value={namaCabang} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="3" style={textRight}>
                      Alamat :
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control value={alamatCabang} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Box>
            <Box sx={[showDataWrapper, secondWrapper]}>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="3" style={textRight}>
                      Telepon :
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control value={teleponCabang} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="3" style={textRight}>
                      PIC :
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control value={picCabang} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="3" style={textRight}>
                      NPWP :
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control value={npwpCabang} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="3" style={textRight}>
                      Istilah :
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control value={istilahCabang} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="3" style={textRight}>
                      COA Titipan :
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        value={`${kodeCOA.kodeCOA} - ${kodeCOA.namaCOA}`}
                        disabled
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Box>
          </Box>
          <Divider sx={dividerStyle} />
        </>
      )}
      <Form onSubmit={searchData}>
        <Box sx={searchBarContainer}>
          <SearchBar value={query} setSearchTerm={setQuery} />
          <Button
            variant="contained"
            type="submit"
            color="primary"
            disableElevation
          >
            Cari
          </Button>
        </Box>
      </Form>
      <Box sx={tableContainer}>
        <ShowTableCabang currentPosts={cabangsData} />
      </Box>
      <Box sx={tableContainer}>
        <Pagination
          count={pages}
          page={page + 1}
          onChange={handleChange}
          color="primary"
          size={screenSize <= 600 ? "small" : "large"}
        />
      </Box>
    </Box>
  );
};

export default TampilCabang;

const subTitleText = {
  fontWeight: "900",
};

const buttonModifierContainer = {
  mt: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};

const dividerStyle = {
  pt: 4,
};

const showDataContainer = {
  mt: 4,
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "row",
  },
};

const showDataWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  maxWidth: {
    md: "40vw",
  },
};

const searchBarContainer = {
  pt: 6,
  display: "flex",
  justifyContent: "center",
};

const tableContainer = {
  pt: 4,
  display: "flex",
  justifyContent: "center",
};

const labelInput = {
  fontWeight: "600",
  marginLeft: 1,
};

const spacingTop = {
  mt: 4,
};

const secondWrapper = {
  marginLeft: {
    sm: 4,
  },
  marginTop: {
    sm: 0,
    xs: 4,
  },
};

const downloadButtons = {
  mt: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};
